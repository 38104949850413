<form (ngSubmit)="onSubmit()">
  <div class="input-group">
    <input
      type="search"
      name="input-search"
      class="form-control"
      placeholder="{{ 'SHARED.SEARCH' | translate }}..."
      [(ngModel)]="globalSearch"
      (ngModelChange)="onSearchInputChange($event)"
    />
    <span class="input-group-append">
      <button [disabled]="globalSearch.length < 3" type="submit" class="btn btn-primary">
        <i class="fa fa-search"></i> {{ 'SHARED.SEARCH' | translate }}
      </button>
    </span>
  </div>
</form>
