import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Alert,
  AlertType,
  ConditionAny,
  CustomAlert,
  CustomAlertSettings,
  SettingsChangeEvent,
  State,
} from '@views/secure/settings/notification/models/notification-settings.model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-notification-alert',
  templateUrl: './notification-alert.component.html',
  styleUrls: ['./notification-alert.component.scss'],
})
export class NotificationAlertComponent implements OnInit {
  @Output() private settings = new EventEmitter<SettingsChangeEvent>();

  @Input() alert: Alert | undefined;
  @Input() alertType: string | undefined;

  isExpanded: boolean | undefined;
  currentAlert: CustomAlert | undefined;
  updatedAlert: Alert | undefined;

  private readonly alerts: CustomAlertSettings = {
    system_status: {
      title: 'TITLE_SYSTEM_STATUS',
      icon: 'fas fa-desktop',
    },
    bandwidth_utilization: {
      title: 'TITLE_BANDWIDTH_UTILIZATION',
      icon: 'fa-tachometer',
      hasThreshold: true,
      unit: '%',
      thresholdLabel: 'LABEL_THERSHOLD',
    },
    wan_status: {
      title: 'TITLE_WAN_STATUS',
      icon: 'fa-sharp fa-solid fa-globe',
    },
    wan_link: {
      title: 'TITLE_WAN_LINK',
      icon: 'fa-link',
      hasThreshold: true,
      thresholdLabel: 'LABEL_LOSS_PERCENTAGE',
    },
    ping_latency: {
      title: 'TITLE_PING_LATENCY',
      icon: 'fa-heartbeat',
      hasThreshold: true,
      unit: 'ms',
      thresholdLabel: 'LABEL_THERSHOLD',
    },
    cpu_usage_system: {
      title: 'TITLE_CPU_USAGE_SYSTEM',
      icon: 'fas fa-laptop',
      hasThreshold: true,
      unit: '%',
      thresholdLabel: 'LABEL_THERSHOLD',
    },
    cpu_usage_user: {
      title: 'TITLE_CPU_USAGE_USER',
      icon: 'fas fa-user',
      hasThreshold: true,
      unit: '%',
      thresholdLabel: 'LABEL_THERSHOLD',
    },
    cpu_temp: {
      title: 'TITLE_CPU_TEMP',
      icon: 'fa-thermometer-empty',
      hasThreshold: true,
      unit: '°C',
      thresholdLabel: 'LABEL_THERSHOLD',
    },
    disk_space: {
      title: 'TITLE_DISK_SPACE',
      icon: 'fa-sharp fa-solid fa-database',
      hasThreshold: true,
      unit: '%',
      thresholdLabel: 'LABEL_THERSHOLD',
    },
    main_memory: {
      title: 'TITLE_MAIN_MEMORY',
      icon: 'fa-sharp fa-solid fa-microchip',
      hasThreshold: true,
      unit: '%',
      thresholdLabel: 'LABEL_THERSHOLD',
    },
    vpn_status: {
      title: 'TITLE_VPN_STATUS',
      icon: 'fa fa-shield',
    },
    device_configuration: {
      title: 'TITLE_DEVICE_CONFIGURATION',
      icon: 'fa fa-cog',
    },
    device_provision: {
      title: 'TITLE_DEVICE_PROVISION',
      icon: 'fa fa-desktop',
    },
    user_activity: {
      title: 'TITLE_USER_ACTIVITY',
      icon: 'fa fa-user-circle',
    },
    lan_status: {
      title: 'TITLE_LAN_STATUS',
      icon: 'fa fa-sitemap',
    },
    system_power: {
      title: 'TITLE_SYSTEM_POWER',
      icon: 'fa fa-power-off',
    },
    vpn_link: {
      title: 'TITLE_VPN_LINK',
      icon: 'fa-link',
      hasThreshold: true,
      thresholdLabel: 'LABEL_LOSS_PERCENTAGE',
    },
    tunnel_latency: {
      title: 'TITLE_TUNNEL_LATENCY',
      icon: 'fa fa-circle-o-notch',
      hasThreshold: true,
      unit: 'ms',
      thresholdLabel: 'LABEL_THERSHOLD',
    },
    wan_ip_change: {
      title: 'TITLE_WAN_IP_CHANGE',
      icon: 'fa-globe',
    },
    tunnel_wan_change: {
      title: 'TITLE_TUNNEL_WAN_CHANGE',
      icon: 'fa fa-exchange',
    },
    wan_failover: {
      title: 'TITLE_WAN_FAILOVER',
      icon: 'fa fa-exchange',
    },
  };

  ngOnInit(): void {
    if (this.alerts[this.alertType]) {
      this.updatedAlert = cloneDeep(this.alert);
      this.currentAlert = this.alerts[this.alertType];
    }
  }

  onToggleView(): void {
    this.isExpanded = !this.isExpanded;
  }

  isHighState(control: ConditionAny): boolean {
    return control.value.state === State.high;
  }

  private isNormalState(control: ConditionAny): boolean {
    return control.value.state === State.normal;
  }

  getHighStateValue(conditionAny: ConditionAny[]) {
    return this.getStateValue(
      conditionAny.find((condition) => this.isHighState(condition) && condition.value.val)
    );
  }

  getStateValue(condition: ConditionAny): number {
    return condition?.value.val;
  }

  isWANOrVPNLinkAlertType(): boolean {
    return (
      this.updatedAlert?.alert_type === AlertType.WANLink ||
      this.updatedAlert?.alert_type === AlertType.VPNLink
    );
  }

  private isStableState(condition: ConditionAny): boolean {
    return condition.value.state === State.stable;
  }

  private isUnstableState(condition: ConditionAny): boolean {
    return condition.value.state === State.unStable;
  }

  private isUnreachableState(condition: ConditionAny): boolean {
    return condition.value.state === State.unReachable;
  }

  getUnstableStateValue(): number {
    return this.updatedAlert.conditions?.any.find(this.isUnstableState)?.value.gte;
  }

  getUnreachableStateValue(): number {
    return this.getStateValue(this.updatedAlert?.conditions?.any.find(this.isUnreachableState));
  }

  private getNumberInputValue(event: Event): number {
    const target = event.target as HTMLInputElement;
    return parseInt(target.value, 10);
  }

  onThresholdHighStateChange(event: Event): void {
    const value = this.getNumberInputValue(event);
    this.updatedAlert.conditions.any.find(this.isHighState).value.val = value;
    this.updatedAlert.conditions.any.find(this.isNormalState).value.val = value - 1;
    this.emitCurrentAlert();
  }

  onUnstableSateChange(event: Event): void {
    const value = this.getNumberInputValue(event);
    this.updatedAlert.conditions.any.find(this.isUnstableState).value.gte = value;
    this.updatedAlert.conditions.any.find(this.isStableState).value.val = value - 1;
    this.emitCurrentAlert();
  }

  onUnreachableSateChange(event: Event): void {
    const value = this.getNumberInputValue(event);
    this.updatedAlert.conditions.any.find(this.isUnreachableState).value.val = value;
    this.updatedAlert.conditions.any.find(this.isUnstableState).value.lte = value - 1;
    this.emitCurrentAlert();
  }

  onEmailChange(): void {
    if (this.updatedAlert) {
      this.updatedAlert.is_enabled = !this.updatedAlert.is_enabled;
    }
    this.emitCurrentAlert();
  }

  onWebChange(): void {
    if (this.updatedAlert) {
      this.updatedAlert.is_event_enabled = !this.updatedAlert.is_event_enabled;
    }
    this.emitCurrentAlert();
  }

  private emitCurrentAlert(): void {
    if (this.alertType && this.updatedAlert) {
      this.settings.emit({ [this.alertType]: this.updatedAlert });
    }
  }
}
