import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceReducer, Devices } from '@secure/devices/store/models/devices.model';

const getDeviceFeatureState = createFeatureSelector<DeviceReducer>('devices');

export const getEditDevice = createSelector(getDeviceFeatureState, (state) => state.editDevice);

export const getCurrentDeviceDetailsByID = (deviceId: number) =>
  createSelector(getDeviceFeatureState, (deviceState: DeviceReducer) => {
    const currentDevice: Devices | undefined = deviceState?.devices.find(
      (device) => device?.id?.toString() === deviceId?.toString()
    );
    return currentDevice;
  });
