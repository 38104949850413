import { Action } from '@ngrx/store';
import {
  Columns,
  ColumnWidth,
  DeviceData,
  GetDeviceResponse,
  SortColumns,
} from '@secure/devices/store/models/devices.model';

export enum ActionTypes {
  FETCH_DEVICES = 'FETCH_DEVICES',
  UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE',
  CLEAR_SELECTED_ROWS = 'CLEAR_SELECTED_ROWS',
  COLUMN_SELECT = 'COLUMN_SELECT',
  COLUMNS_SELECT_ALL = 'COLUMNS_SELECT_ALL',
  COLUMN_DESELECT = 'COLUMN_DESELECT',
  SHUFFLED_COLUMNS = 'SHUFFLED_COLUMNS',
  COLUMN_SEARCH = 'COLUMN_SEARCH',
  UPDATE_FLOATING_FILTER = 'UPDATE_FLOATING_FILTER',
  CLEAR_COLUMNS_SEARCH = 'CLEAR_COLUMNS_SEARCH',
  CLEAR_COLUMNS_SORT = 'CLEAR_COLUMNS_SORT',
  UPDATE_SELECTED_ROWS = 'UPDATE_SELECTED_ROWS',
  UPDATE_COLUMNS_SORT = 'UPDATE_COLUMNS_SORT',
  UPDATE_COLUMN_WIDTH = 'UPDATE_COLUMN_WIDTH',
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
  SET_LOCAL_CONFIGURATION = 'SET_LOCAL_CONFIGURATION',
  DELETE_DEVICES = 'DELETE_DEVICES',
  SET_EDIT_DEVICE = 'SET_EDIT_DEVICE',
  DEVICE_PACKAGE_UPGRADE = 'DEVICE_PACKAGE_UPGRADE',
}

export class FetchDevices implements Action {
  readonly type = ActionTypes.FETCH_DEVICES;
  constructor(public payload: GetDeviceResponse) {}
}

export class GlobalSearch implements Action {
  readonly type = ActionTypes.GLOBAL_SEARCH;
  constructor(public payload: string) {}
}

export class UpdateCurrentPage implements Action {
  readonly type = ActionTypes.UPDATE_CURRENT_PAGE;
  constructor(public payload: number) {}
}

export class ClearSelectedRows implements Action {
  readonly type = ActionTypes.CLEAR_SELECTED_ROWS;
}

export class ColumnSelect implements Action {
  readonly type = ActionTypes.COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class ColumnsSelectAll implements Action {
  readonly type = ActionTypes.COLUMNS_SELECT_ALL;
}

export class ColumnDeSelect implements Action {
  readonly type = ActionTypes.COLUMN_DESELECT;
  constructor(public payload: string) {}
}

export class ShuffledColumns implements Action {
  readonly type = ActionTypes.SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}
export class ColumnSearch implements Action {
  readonly type = ActionTypes.COLUMN_SEARCH;
  constructor(public payload: any) {}
}

export class UpdateFloatingFilter implements Action {
  readonly type = ActionTypes.UPDATE_FLOATING_FILTER;
  constructor(public payload: boolean) {}
}

export class ClearColumnSearch implements Action {
  readonly type = ActionTypes.CLEAR_COLUMNS_SEARCH;
}

export class ClearColumnsSort implements Action {
  readonly type = ActionTypes.CLEAR_COLUMNS_SORT;
}

export class UpdateSelectedRows implements Action {
  readonly type = ActionTypes.UPDATE_SELECTED_ROWS;
  constructor(public payload: DeviceData[]) {}
}

export class UpdateColumnsSort implements Action {
  readonly type = ActionTypes.UPDATE_COLUMNS_SORT;
  constructor(public payload: SortColumns[]) {}
}

export class UpdateColumnWidth implements Action {
  readonly type = ActionTypes.UPDATE_COLUMN_WIDTH;
  constructor(public payload: ColumnWidth[]) {}
}

export class DeleteDevices implements Action {
  readonly type = ActionTypes.DELETE_DEVICES;
  constructor(public payload: number[]) {}
}

export class SetEditDevice implements Action {
  readonly type = ActionTypes.SET_EDIT_DEVICE;
  constructor(public payload: DeviceData) {}
}

export class UpgradeDevice implements Action {
  constructor(public payload: number[]) {}
  readonly type = ActionTypes.DEVICE_PACKAGE_UPGRADE;
}

export type Actions =
  | FetchDevices
  | UpdateCurrentPage
  | ClearSelectedRows
  | ColumnSelect
  | ColumnsSelectAll
  | ColumnDeSelect
  | ShuffledColumns
  | ColumnSearch
  | UpdateFloatingFilter
  | ClearColumnSearch
  | ClearColumnsSort
  | UpdateSelectedRows
  | UpdateColumnsSort
  | UpdateColumnWidth
  | GlobalSearch
  | DeleteDevices
  | SetEditDevice
  | UpgradeDevice;
